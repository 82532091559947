<template>
  <main class="main">
    <div class="prg__request prg__request--page">
      <div class="container">
        <BreadcrumbsComponent :title="page ? page.title : 'Оставить заявку на лизинг'" />
        <LeasingRequestModal />
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import LeasingRequestModal from "components/modals/LeasingRequest.vue";
import { cityIn } from "lvovich";

export default {
  name: "RequestPage",
  async asyncData({ store, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_CLAIM_PAGE");
  },
  components: {
    LeasingRequestModal,
    BreadcrumbsComponent,
  },
  computed: {
    page() {
      let page;
      if (this.$store.state.claim_page && this.$store.state.claim_page.page) {
        page = this.$store.state.claim_page.page;
      }
      return page;
    },
  },
  methods: {
    getImg(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.url) {
        return this.$store.state.api + item.head_img.img.url;
      }
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let metaName = this.page.title;
    let metaFullPath = this.$route.fullPath;
    let alternateLink = this.$store.state.alternateLink;
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    let data = {};
    if (this.$store.state.claim_page && this.$store.state.claim_page.page) {
      data = this.$store.state.claim_page.page;
    }
    let img = this.getImg(data) || "";
    const title = (data.meta_title || data.title).replace(/{{ city }}/g, `в ${city}`);
    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              {
                "@type": "ListItem",
                position: "2",
                name: metaName,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "description",
          name: "description",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: img,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
};
</script>
<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/prg.styl"
</style>
